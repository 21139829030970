import bet from './Bet';
import history from './History';
import matchResult from './MatchResult';



// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, memberManagement, reports, bets]
    items: [bet,history,matchResult]
};

export default menuItems;
