import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
// import Logo from '../Logo';
import { MENU_OPEN } from '../../store/actions';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    const defaultId = useSelector((state) => state.customization.defaultId);
    const dispatch = useDispatch();
    return (
        <ButtonBase disableRipple onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} to={"/"}>
            {/* <Logo /> */}
            <h2 style={{margin: "10px 0 0 70px"}} className='logo-style'>M-bet</h2>
        </ButtonBase>
    );
};

export default LogoSection;
