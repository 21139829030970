 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
 import React from 'react'
import { ButtonBase } from '@mui/material'
import { useNavigate } from 'react-router-dom';

export default function BetHome() {
  const navigate = useNavigate();

  const handleNavigationClick = (link) =>{
    navigate(`${link}`);
  }
  return (
    <div style={{padding: '4rem 10rem'}}>
      <ButtonBase component="div"  style={{ width: "100%", borderRadius: "2.2rem",background: "gray", marginBottom: "3rem", backgroundSize: "cover" }} onClick={() => handleNavigationClick("/bet/parlay")}>
        <h2 className='white' style={{ margin: 0, textAlign: 'center', padding: "3.5rem 0", color: 'white' }}>Parlay</h2>
      </ButtonBase>
      <ButtonBase component="div" style={{ width: "100%", borderRadius: "2.2rem", background: "slategray", marginBottom: "3rem", backgroundSize: "cover" }} onClick={() => handleNavigationClick("/bet/bodyUN")}>
        <h2 className='white' style={{ margin: 0, textAlign: 'center', padding: "3.5rem 0",color: 'white' }}>Body/UN</h2>
      </ButtonBase>
    </div>
  )
}
