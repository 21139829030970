 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { Input } from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import {Img} from 'react-image'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import {ButtonBase} from '@mui/material';

import Loader from '../loader';
import Alert from '../alert';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilterLeague({ leaguesArr,filterLeagues,hanldeFilterLeaguesClose, open ,handleFilterMatchesByLeagues }) {

    const [filterListItem, setFilterListItem] = useState([]);
    const [data, setData] = useState([]);
    const [leagues, setLeagues] = useState([]);
    const [loading,setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [selectedLeague,setSelectedLeague] = useState({})

    const handleListItemClick = (league) => {
        const leagueId = league.id;
        let copyArr;
        // copyArr = [...filterListItem];
        copyArr = [];
        // copyArr.push(leagueId)
        (filterListItem.includes(leagueId)) ? copyArr = copyArr.filter(v => v != leagueId) : copyArr.push(leagueId)
        setFilterListItem(copyArr)
        setSelectedLeague(league)
    }

    const handleSearch = (e) =>{
        let filteredData;
        filteredData = data.filter(v => v.value_en.toLowerCase().includes(e.currentTarget.value.toLowerCase()));
        setLeagues(filteredData)
    }

    useEffect(()=>{
        setLeagues(leaguesArr)
        setData(leaguesArr)
        setFilterListItem(filterLeagues)
        setLoading(false)
    },[])

    const handleCloseAlert =()=>{
        setOpenAlert(false)
    }

    return (
        <div>
            <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
            <Dialog
                fullWidth
                maxWidth="xs"
                PaperProps={{
                    style: {
                        position: 'absolute',
                        width: "90vw",
                        top: 0,
                        bottom: 0,
                        backgroundColor: "lightgray"
                    },
                }}
                open={open}
                onClose={hanldeFilterLeaguesClose}
                TransitionComponent={Transition}
            >
       
                <AppBar sx={{ position: 'relative'}} className="header-filter-league">
                    <Toolbar sx={{ justifyContent: "space-between", backgroundColor: "#021928" }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={hanldeFilterLeaguesClose}
                            aria-label="close"
                        >
                            <CloseIcon fontSize='large' />
                        </IconButton>
                        <Input placeholder='Search...' className='search-league' size='large' onChange={handleSearch}/>
                        <Button autoFocus color="inherit" onClick={()=>{handleFilterMatchesByLeagues(filterListItem,selectedLeague);hanldeFilterLeaguesClose();}}
                            sx={{ fontSize: "1.2rem"  }}>
                            Filter
                        </Button>
                    </Toolbar>
                </AppBar>
                {loading && <Loader/>}
                {!loading &&
                <List   id='leagueFilter'
                css={css`
                        overflow-y: scroll;
                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                        scrollbar-width: none;  /* Firefox */
                        ::-webkit-scrollbar { 
                        display: none !important;  /* Safari and Chrome */
                    }
                    `}
                >
                    {leagues.map(v => {
                        return (
                            <ButtonBase component="div" style={{display: "block"}}>
                                <ListItem  sx={{ color: "white" }} onClick={() => handleListItemClick(v)} key={v.id}>
                                    <ListItemAvatar >
                                        <Avatar  style={{borderRadius: "40px",width: '42px',height: '42px'}}>
                                        <Img
                                          width={42}
                                          height={42}
                                          style={{borderRadius: "45px",objectFit: "cover"}}
                                          src={v.logo}
                                          loader={<Skeleton width={42} height={42} circle/>}
                                        />  
                                      
                                        </Avatar>
                                        {/* <Avatar alt="Logo" src={v.logo} /> */}
                                    </ListItemAvatar>
                                    <ListItemText primary={v.value_en}
                                        primaryTypographyProps={{ style: { color: 'white', fontSize: "1.1rem" ,color: 'black'} }}
                                    />
                                {filterListItem.includes(v.id)? <RadioButtonCheckedIcon sx={{ width: 24, height: 24, color: "#5e35b1" }} /> :  <RadioButtonUncheckedIcon sx={{ width: 24, height: 24 }} />}
                                </ListItem>
                            </ButtonBase>
                        )
                    })}

                </List>
                }
            </Dialog>
        </div>
    );
}