import React, { useRef } from 'react'
/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react'
import { Space,Table ,DatePicker,Pagination, Select, Input, Button} from 'antd';
import { useState } from 'react';
import { useEffect } from 'react';
import dayjs from 'dayjs';

import axios from '../apiClient';
import Alert from '../alert';
import moment from 'moment/moment';
import { useLocation, useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';

const {RangePicker} = DatePicker;

export default function BetHistory() {
  const navigate = useNavigate();
  const location = useLocation();
  const [page,setPage] = useState(1);
  const [slips,setSlips] = useState([]);
  const [totalSlips, setTotalSlips] = useState();
  const [loading,setLoading] = useState(false)
  const [errorMsg, setErrorMsg] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [slipId,setSlipId] = useState('');
  const [date,setDate] = useState({})
  const didMount = useRef(false);


  async function fetchData() {
    setLoading(true)
    const token = localStorage.getItem("token");
    const {id: userId} = jwtDecode(token);
    let res = await axios.get(`/history/getSlipByMonth?slipId=${slipId}&memberId=${userId}&startDate=${date.startDate} 00:00:00&endDate=${date.endDate} 23:59:59&current=${page}&pageSize=10`)

    if (res.code != 200) {
        if(res.code == 404){
            setLoading(false)
            setSlips([])
            return
        }
        setErrorMsg({ code: res.code, msg: res.message })
        setOpenAlert(true)
        setLoading(false)
        return
    }
    setSlips(res.data.slips)
    setTotalSlips(res.data.pagination.total)
    setLoading(false)
  }


  const handleCloseAlert = () => setOpenAlert(false)
  
  const handlePageChange = (page) => setPage(page)
  
  const handleSearchSlipId = ({currentTarget}) => setSlipId(currentTarget.value);
  
  const handleDateChange = async (v,dateString) => setDate({startDate: dateString[0],endDate: dateString[1]});

  const handleRowClick = (rowData) =>{
    navigate('/bet/detail',{state: {rowData,date,page,slipId}})
  }
  
  useEffect(()=> {
    if(!didMount.current){
      didMount.current = true;
      if(location?.state){
        const {date,slipId,page} = location.state;
        setSlipId(slipId);
        setDate(date);
        setPage(page)
      }else{
        const today = moment().format('YYYY-MM-DD');
        const tmr = moment().add(1,'days').format('YYYY-MM-DD');
        setDate({startDate: today,endDate: tmr});
      }
    }else{
      fetchData()
    }
  },[date,page,slipId])

  const columns = [      
      {
        title: 'Slip Id',
        dataIndex: 'shortId',
        key: 'id',
      },
      {
        title: 'Bet Amount',
        dataIndex: 'betAmount',
        key: 'id',
      },
      {
        title: 'Match',
        dataIndex: 'bet',
        render: (bet) =>  bet[0].match.homeTeam.name_en + "-" +  bet[0].match.awayTeam.name_en,
        key: 'id',
      },
      {
        title: 'Odd',
        key: 'id',
        dataIndex: 'bet',
        render: (bet) =>  bet[0].betData.converted,
      },
      {
        title: 'Odd Type',
        key: 'id',
        dataIndex: 'betType',
      },
      {
        title: 'Bet Status',
        key: 'id',
        dataIndex: 'betStatus',
      },
      {
        title: 'Date',
        key: 'id',
        dataIndex: 'betDate',
        render: (date) => moment(date).format("DD-MM-YYYY hh:mm A"),
      },
      {
        title: 'Action',
        render: () => {
          const color = `#655DBB`
          return (
          <Space size="middle">
              <Button css={css`color: black;border-color: ${color};:hover{border-color: ${color};}`} color='success' variant="outlined" >
                  Detail
              </Button>
          </Space>
        )},
      },

  ];

    return (
      <div>
    <div>
       <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
       <div style={{display: 'flex'}}>
         <Space direction="vertical" size={12}>
          <RangePicker
            value={date.startDate ? [dayjs(date.startDate, 'YYYY-MM-DD'), dayjs(date.endDate, 'YYYY-MM-DD')] : ''}
            onChange={(v,dateString)=> handleDateChange(v,dateString)}/>
          </Space>
          <Input placeholder="SlipId..."  style={{width: "20%",marginLeft: "2rem"}} onChange={handleSearchSlipId} value={slipId}/>
       </div>
      <Table onRow={(rowData)=>{
          return(
            {onClick: () =>handleRowClick(rowData)}
          )
       }}
      loading={loading} scroll={{ x: 'max-content' }} css={css`padding-top: 2rem;`} pagination={false} columns={columns} dataSource={slips} />
      <Pagination className='pagination' css={css`display: flex;justify-content: end;padding-top: 1rem;`} current={page} total={totalSlips} pageSize={10} showSizeChanger={false} onChange={(page) => handlePageChange(page)} />
    </div>
  </div>
  )
}

