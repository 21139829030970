const config = {
    basename: '',
    defaultPath: '/dashboard/default',
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    backendApi: "https://api.kl36.bet",
    socketApi: "https://api.kl36.bet",
//     backendApi: "https://dev.ssmain-mbet.com/devapi/api",
//     socketApi: "https://dev.ssmain-mbet.com"
};

export default config;
