import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';


// assets
import { IconMenu2,IconCoin } from '@tabler/icons';
import { useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import config from '../../config';
import axios from '../apiClient';
import Alert from '../alert';
import { useNavigate } from 'react-router';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const [amount,setAmount] = useState(0);
    const [errorMsg, setErrorMsg] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [agentName,setAgentName] = useState('')
    const navigate = useNavigate()
    // get data from backend when componenet is first mounted
  async function fetchData() {
    const token = localStorage.getItem("token");
    const {id: holderId,name} = jwtDecode(token)

    let data = await axios.get(`/wallet/getAmount?holderId=${holderId}`)
    if (data.code != 200) {
        setErrorMsg({ code: data.code, msg: data.message })
        setOpenAlert(true)
        return
    }
    setAmount(data.data.amount)
    setAgentName(name)
}
  //--------------------------------------------------
    useEffect(()=>{
        fetchData()
    },[])
    const theme = useTheme();
    const handleCloseAlert =()=>{
        setOpenAlert(false)
    }
    const handleLogout = () =>{
        localStorage.removeItem("token");
        navigate('/login')
    }
    return (
        <>
           <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
            {/* logo & toggler button */}
            <Box
                sx={{
                    width: 228,
                    display: 'flex',
                    [theme.breakpoints.down('md')]: {
                        width: 'auto'
                    }
                }}
            >
                <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                    <LogoSection />
                </Box>
                <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                    <Avatar
                        variant="rounded"
                        sx={{
                            ...theme.typography.commonAvatar,
                            ...theme.typography.mediumAvatar,
                            transition: 'all .2s ease-in-out',
                            background: theme.palette.secondary.light,
                            color: theme.palette.secondary.dark,
                            '&:hover': {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light
                            }
                        }}
                        onClick={handleLeftDrawerToggle}
                        color="inherit"
                    >
                        <IconMenu2 stroke={1.5} size="1.3rem" />
                    </Avatar>
                </ButtonBase>
            </Box>

            {/* header search */}
            {/* <SearchSection /> */}
            {/* <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} /> */}
             <div style={{display: "flex",justifyContent: "space-between",alignItems: "center",width: "100%"}}>
                 <h3 style={{marginLeft: "2rem"}} >Home Site</h3>
                  <div style={{display: "flex",justifyContent: "space-between",alignItems: "center"}}>
                      <div style={{display: "flex",alignItems: "center"}}>
                          <IconCoin color='#0E8388'/>
                          <h3 style={{marginRight: "80px"}}><span style={{fontWeight: 'normal'}}>Units:</span><span style={{fontWeight: 'bold',marginLeft: "0.2rem"}}>{Number(amount).toLocaleString()}</span> </h3>
                      </div>

                      <h3 style={{marginRight: "1rem",fontWeight: 'bold'}}>{agentName}</h3>
                      <ButtonBase style={{marginRight: "1rem",marginLeft: "5rem",borderRadius: "1rem"}} onClick={handleLogout}>
                          <h3>Logout</h3>
                      </ButtonBase>
                  </div>
             </div>
            {/* <ProfileSection /> */}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
