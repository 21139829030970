import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@mui/material/styles';
import MainLayout from "./components/mainLayout";
import Dashboard from "./components/dashboard";
import { CssBaseline, StyledEngineProvider } from '@mui/material';

import themes from './themes';


import Login from "./components/login/login";
import PrivateRoute from "./components/privateRoute";
import { pageContext } from './context';
import { useState } from "react";

import BetHome from "./components/bet/betHome";
import BetHistory from "./components/bet/betHistory";
import MatchResult from "./components/reports/matchResult";
import Parlay from "./components/bet/parlay";
import BodyUN from "./components/bet/bodyUN";
import BetSummary from "./components/bet/beSummary";
import BetDetail from "./components/bet/betDetails";

function App() {
  const customization = useSelector((state) => state.customization);
  const [currentPages,setPages] = useState({memberManagementListPage: 1,subAgentListPage: 1});

  const changePages = (pageObj) =>{
    setPages({...currentPages,...pageObj})
  }
  return (
    // <BrowserRouter>
    <pageContext.Provider value={{currentPages,changePages}}>
    <HashRouter>
      <ThemeProvider theme={themes(customization)}>
      <CssBaseline />
        <Routes>
         <Route path="/login" element={<Login/>}/>
         <Route element={<PrivateRoute/>}>
          <Route path="/" element={<MainLayout />}>
             <Route path="/bet" element={<BetHome />}/>
             <Route path="/bet/parlay" element={<Parlay />}/>
             <Route path="/bet/bodyUN" element={<BodyUN />}/>
             <Route path="/bet/summary" element={<BetSummary />}/>
             <Route path="/bet/detail" element={<BetDetail />}/>
             <Route path="/history" element={<BetHistory />}/>
             <Route path="/matchResult" element={<MatchResult />}/>
          </Route>
         </Route>
      </Routes>
      </ThemeProvider>
    </HashRouter>
    </pageContext.Provider>
    // {/* // </BrowserRouter> */}
  );
}

export default App;
