import React,{useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom';
import {ButtonBase, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, Slide,Button} from '@mui/material';
import { io } from 'socket.io-client';

import Card from "./matchCard"
import axios from '../apiClient';
import Alert from '../alert';
import Loader from '../loader';
import { useEffect } from 'react';
import ReactToPrint from 'react-to-print';
import moment from 'moment';
import PrintCard from './printMatchCard';
import config from '../../config'

export default function BetSummary() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading,setLoading] = useState(false);
  const [openOddChangesWarnBox,setOpenOddChangesWarnBox] = useState(false);
  const [errorMsg, setErrorMsg] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [openComfirmBox,setOpenComfirmBox] = useState(false);
  const [removeCard,setRemoveCard] = useState({});
  const [selectedCard,setSelectedCard] = useState([]);
  const [selectedMatches,setSelectedMatches] = useState([]);
  const [printerDialogOpen,setPrinterDialogOpen] = useState(false);
  const [oddChanged,setOddChanged] = useState(false);
  const [commission,setCommisssion]= useState(0);
  const [slipInfo,setSlipInfo] = useState({});
  const socketRef = useRef();
  let printerRef = null;

  // const {selectedMatches,selectedCard,betAmount} = location.state;
  const betAmount = location.state?.betAmount;
  const estimatedWin = betAmount * (2 ** selectedMatches.length);
  const parlay = location.state?.selectedCard?.length > 1 ;
  const single = location.state?.selectedCard?.length == 1 ;


   
  useEffect(()=>{
    getCommission()
    setSelectedCard(location.state?.selectedCard)
    setSelectedMatches(location.state?.selectedMatches);
    setOddChangeToFalse()

    socketRef.current = io(config.socketApi,{
      auth: {
          token: localStorage.getItem("token")
      },

    });
    socketRef.current.io.on("error", (error) => {
    // console.log("error from socket",error)
    });

    socketRef.current.on('oddUpdated',oddUpdated);

    return () => {
        socketRef.current.off('oddUpdated');
        socketRef.current.disconnect();
        const idsString = localStorage.getItem('timeoutIds')
        if(idsString){
          let idsArrays = JSON.parse(idsString);
          idsArrays.forEach(id => {
              clearTimeout(id);
          });
          localStorage.setItem('timeoutIds',JSON.stringify([]));
        }
    };
  },[])

  const handleCloseOddChangesWarnBox = () => setOpenOddChangesWarnBox(false);

  const handleBetClick = () =>{
    if(oddChanged){
      setOpenOddChangesWarnBox(true);
      setOddChanged(false)
    }else{
      handleUserBetting();
    }
  }

  const getCommission = async() => {
    const {selectedCard,selectedMatches} = location.state;

    let  com = 0;
    const res = await axios.get(`/info/getCommission`)
    if(res.code != 200) return 
    const {
      nine_to_eleven_mix_parlay_commission,
      single_big_match_commission,
      single_small_match_commission,
      three_to_eight_mix_parlay_commission,
      two_mix_parlay_commission
    } = res.data;

    if(selectedCard > 1){
      if (selectedCard <= 2){
        com = two_mix_parlay_commission;
      }else if(selectedCard <= 8 && selectedCard > 2){
        com = three_to_eight_mix_parlay_commission;
      }else if(selectedCard > 8){
        com = nine_to_eleven_mix_parlay_commission;
      }
    }else{
      const popular = selectedMatches[0].popular;
      if(popular == 0) { // it means not popular
        com = single_small_match_commission;
      }else{
        com = single_big_match_commission;
      }
    }
    setCommisssion(com)
  }

  const calculateCommission = (totolWinAmount)  => {
    if(commission == null ) return totolWinAmount
     const commissionAmount = totolWinAmount  * (commission/100);
     return totolWinAmount - commissionAmount ;
  }

  const handleUserBetting = async () =>{
    setOpenOddChangesWarnBox(false)
    setLoading(true)
    const body ={betAmount,betType: `${parlay ? "mix_parlay" : "single"}`,betData: selectedCard}
    const data = await axios.post(`/bet/setBet`,body)
    setErrorMsg({code: data.code,msg: data.message})
    setOpenAlert(true)
    setLoading(false)
    if(data.code != 200) return 
    setSlipInfo(data.data)
    setPrinterDialogOpen(true)
    // setTimeout(()=>{
    //   navigate("/history")
    // },[1500])
  }

  const handleCloseAlert =()=> setOpenAlert(false);

  const handleCloseComfirmBox = () => setOpenComfirmBox(false);

  const handleOpenComfirmBox = (removedCard) => {
    setRemoveCard(removedCard)
    setOpenComfirmBox(true);
  };

  const handleOkRemove = () => {
    const updatedSelectedCard = [...selectedCard].filter(card => !(card.matchId == removeCard.matchId && card.periods == removeCard.periods));
    const updatedSelectedMatches = [...selectedMatches].filter(match => !(match.matchId == removeCard.matchId && match.selectedPeriods == removeCard.periods));
    if(updatedSelectedCard.length == 1 ){
      setErrorMsg({ code: 404, msg: `Bet Match must be at least two`,info: true })
      setOpenAlert(true);
      handleCloseComfirmBox();
      return
  }
    setSelectedCard(updatedSelectedCard);
    setSelectedMatches(updatedSelectedMatches);
    handleCloseComfirmBox();
  }

  const resetPrinter = () => {
    setPrinterDialogOpen(false);
    setSlipInfo({});
    navigate(-1)
  }

  const renderPrintDialog         =       () => {
    return (
      <Dialog
      open={printerDialogOpen}
      onClose={()=>resetPrinter()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
     <DialogTitle id="alert-dialog-title" style={{fontSize: '1.2rem',marginLeft: 'auto',marginRight: 'auto'}}>Bet Successful!</DialogTitle>
     <DialogActions>
        {renderReceiptPrinterView()}
     </DialogActions>
      <DialogActions>
        <Button onClick={()=>resetPrinter()} color="primary">
          Cancel
        </Button>
        <Button >
          <ReactToPrint
          trigger={() => <Button>Print</Button>}
          content={() => printerRef}
          />
        </Button>
        
      </DialogActions>
    </Dialog>
    )
  }

  const renderReceiptPrinterView  =       () => {
    
    return (
    <div ref={el => (printerRef = el)} style={{padding: "0 1rem",width: '300px'}}>
      <div style={{padding: "0"}} className="white">
         <div style={{display: "flex",justifyContent: "center",marginBottom: "1rem",marginTop: '1rem'}}>
           <h3 style={{margin: 0}}>Bet Slip</h3>
         </div>
         <div style={{padding: "0 1rem 1rem 1rem",border: "1px solid grey",borderRadius: "1.3rem"}}>
          <div style={{display: "flex",justifyContent: 'space-between',margin: "1rem 0"}}>
             <h3 style={{margin: 0}}>ID</h3>
             <h3 style={{margin: 0}}>{slipInfo?.shortId}</h3>
           </div>
           <div style={{display: "flex",justifyContent: 'space-between',margin: "1rem 0"}}>
             {single ? <h3 style={{margin: 0}}>Type</h3> :<h3 style={{margin: 0}}>Parlay</h3>}
             {single ? <h3 style={{margin: 0}}>HDP</h3>  : <h3 style={{margin: 0}}>{selectedCard.length}</h3>}
           </div>
           <div  style={{display: "flex",justifyContent: 'space-between',margin: "0",paddingBottom: "1rem"}}>
             <h3 style={{margin: 0}}>Betted money</h3>
             <h3 style={{margin: 0}}>{Number(slipInfo?.amount).toLocaleString()}</h3>
           </div>
           <div style={{display: "flex",justifyContent: 'space-between',margin: "0.3rem 0"}}>
             <h4 style={{margin: 0}}>{moment(slipInfo?.date).format('DD-MM-YYYY h:mmA')}</h4>
           </div>
         </div>
      </div>
      <div className='white' >
         {
            selectedMatches.map((v)=>{
                let copyArr =selectedCard.length > 0 ? [...selectedCard] : []
                let obj = copyArr.filter(vv=>vv.matchId == v.matchId && vv.periods == v.selectedPeriods)[0]
                if(!obj) obj={};
                
                return(
                    <PrintCard summary betTime={obj.periods} key={`${v.matchId}${v.selectedPeriods}`} matchData={v} handleBetMatchClick={()=>{}} selectedBetCard={[obj]} handleOpenComfirmBox={handleOpenComfirmBox}/>
                )
            })
        }
      </div>
      
      </div>
    )
  }

  const oddUpdated = (data) => {
    let localData = [];
    const socketData = data;
    
    setSelectedMatches((preState)=>{
      for (let i = 0; i < socketData.length; i++) {
            let a = []; //changed local data without "change" params
            localData = [...preState];
            if(localData.length == 0) return localData;
            const socketMatch = socketData[i];
            const {matchId: remoteMatchId,hdp: remoteHdp,ou: remoteOu,periods: remotePeriod} = socketMatch;
            const localMatches = localData;
            for (let y = 0; y < localMatches.length; y++) {
                const {matchId :localMatchId,odd: localOdds} = localMatches[y];
                if(localMatchId != remoteMatchId) continue;
                const localOdd = localOdds.find(odd=> odd.periods == remotePeriod);
                if(!localOdd) continue;
                const copylocalOdd = JSON.parse(JSON.stringify(localOdd));
                localOdd.hdp = remoteHdp;
                localOdd.ou  = remoteOu;
                a = JSON.parse(JSON.stringify(localData));
                if(remoteHdp.converted == copylocalOdd?.hdp?.converted && remoteOu.converted == copylocalOdd?.ou?.converted) continue;
                if(remoteHdp.converted != copylocalOdd?.hdp?.converted)  remoteHdp.change = true;
                if(remoteOu.converted != copylocalOdd?.ou?.converted) remoteOu.change = true;
                setOddChanged(true)
            }   
            const timeoutId = setTimeout(()=>{
                setOddChangeToFalse()
            },5000)
            let idsString = localStorage.getItem('timeoutIds')
            if(!idsString) idsString = JSON.stringify([]);
            let idsArrays = JSON.parse(idsString);
            idsArrays.push(timeoutId);
            localStorage.setItem('timeoutIds',JSON.stringify(idsArrays));
        }
        return localData
    })

  
    setSelectedCard((preState)=>{
      localData = [...preState];
      if(localData.length == 0) return localData;
      for (let i = 0; i < socketData.length; i++) {
          const socketMatch = socketData[i];
          const {matchId: remoteMatchId,hdp: remoteHdp,ou: remoteOu,periods: remotePeriod} = socketMatch;

          const localSelectedMatches = localData;
          for (let y = 0; y < localSelectedMatches.length; y++) {
              const {matchId :localMatchId,periods: localPeriods,oddType: localOddType} = localSelectedMatches[y];
              if(localMatchId != remoteMatchId) continue;
              if(localPeriods != remotePeriod) continue;
              localSelectedMatches[y].converted = socketMatch[localOddType].converted;
          } 
      }
      return localData
    })
    
  }

  
  const setOddChangeToFalse = () => {
    setSelectedMatches((preState) => {
      const matches = [...preState];
      for (let x = 0; x < matches.length; x++) {
        const match = matches[x];
        const odd = match?.odd[0];
        if (odd.hdp.change) odd.hdp.change = false;
        if (odd.ou.change) odd.ou.change = false;
      }
      return matches
    })
  }
  
  const setOddChangeToTrue = () => {
    setSelectedMatches((preState) => {
      const matches = [...preState];
      for (let x = 0; x < matches.length; x++) {
        const match = matches[x];
        const odd = match?.odd[0];
        if (odd.hdp.hasOwnProperty('change')) odd.hdp.change = true;
        if (odd.ou.hasOwnProperty('change')) odd.ou.change = true;
      }
      return matches
    })
  }

  return (
    <div style={{padding: "0 15rem"}}>
      <div className='white' >
      <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
      {openComfirmBox ? <ComfirmBox openComfirmBox={openComfirmBox} handleCloseComfirmBox={handleCloseComfirmBox} handleOkRemove={handleOkRemove}/> : ""}
      {openOddChangesWarnBox ? <OddChangesWarmBox setOddChangeToTrue={setOddChangeToTrue} openOddChangesWarnBox={openOddChangesWarnBox} handleCloseOddChangesWarnBox={handleCloseOddChangesWarnBox} handleUserBetting={handleUserBetting}/> : ""}
         {
            selectedMatches.map((v)=>{
                let copyArr =selectedCard.length > 0 ? [...selectedCard] : []
                let obj = copyArr.filter(vv=>vv.matchId == v.matchId && vv.periods == v.selectedPeriods)[0]
                if(!obj) obj={};
                
                return(
                    <PrintCard summary betTime={obj.periods} key={`${v.matchId}${v.selectedPeriods}`} matchData={v} handleBetMatchClick={()=>{}} selectedBetCard={[obj]} handleOpenComfirmBox={handleOpenComfirmBox} parlay={parlay}/>
                )
            })
        }
      </div>
      <div style={{padding: "0 2rem 1rem 2rem",marginTop: "3rem"}} className="white">
         <div style={{display: "flex",justifyContent: "center",marginBottom: "1rem"}}>
           <h3 style={{margin: 0}}>Bet Summary</h3>
         </div>
         <div style={{padding: "1rem",border: "1px solid grey",borderRadius: "1.3rem"}}>
           <div style={{display: "flex",justifyContent: 'space-between',margin: "1rem 0"}}>
             {single ? <h4 style={{margin: 0}}>Type</h4> :<h4 style={{margin: 0}}>Parlay</h4>}
             {single ? <h3 style={{margin: 0}}>HDP</h3>  : <h3 style={{margin: 0}}>{selectedCard.length}</h3>}
           </div>
           <div  style={{display: "flex",justifyContent: 'space-between',margin: "0 0 1rem 0",paddingBottom: "1rem",borderWidth: "0 0 1px 0",borderStyle: "dotted",borderColor: "grey"}}>
             <h4 style={{margin: 0}}>Betted money</h4>
             <h3 style={{margin: 0}}>{Number(betAmount).toLocaleString()}</h3>
           </div>
           <div  style={{display: "flex",justifyContent: 'space-between',margin: "0 0 1rem 0"}}>
             <h4 style={{margin: 0}}>Estimated Win</h4>
             <h3 style={{margin: 0}}>{calculateCommission(estimatedWin).toLocaleString()}</h3>
           </div>
         </div>
      </div>
      <div style={{display: "flex",justifyContent: "center",alignItems:"center",padding: "0 2rem 6rem 2rem",marginTop: "4rem"}}>
        <ButtonBase onClick={handleBetClick} component="button" style={{color: "white",display: "block",backgroundColor: "lightslategray",padding: "1.6rem 0",borderRadius: "2rem",width: "100%",fontFamily: "inherit",justifyContent: "center",alignItems: "center"}}>
            {loading ? <Loader btn/>: <h3 style={{textAlign: "center",margin: 0,color: 'black'}}>Bet</h3>}
        </ButtonBase>
      </div>
      {renderPrintDialog()}
    </div>
  )
}

const ComfirmBox = ({openComfirmBox,handleCloseComfirmBox,handleOkRemove}) =>{
  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  return (
    <Dialog
    open={openComfirmBox}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleCloseComfirmBox}
    aria-describedby="alert-dialog-slide-description"
    PaperProps={{style: {backgroundColor: "lightgray",width: "70%"}}}
  >
    <DialogTitle style={{color: "white",fontSize: "18px",textAlign: 'center',color: "black" }}>Are you sure you want to remove?</DialogTitle>
    <DialogContent sx={{paddingLeft: 0,paddingRight: 0}}>
      <ListItem button sx={{ color: "white",fontSize: "18px",justifyContent: 'center',padding: '1rem 0',color: "black" }} onClick={() => handleOkRemove()}>
         Ok
       </ListItem>
       <ListItem button sx={{ color: "white",fontSize: "18px",justifyContent: 'center',padding: '1rem 0',color: "black"  }} onClick={() => handleCloseComfirmBox()}>
         No
       </ListItem>
    </DialogContent>

  </Dialog>
  )
}

const OddChangesWarmBox = ({setOddChangeToTrue,openOddChangesWarnBox,handleCloseOddChangesWarnBox,handleUserBetting}) =>{

  
  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const handleCheckAgain = () => {
    handleCloseOddChangesWarnBox();
    setOddChangeToTrue();
  }

  return (
    <Dialog
    open={openOddChangesWarnBox}
    TransitionComponent={Transition}
    keepMounted
    onClose={handleCloseOddChangesWarnBox}
    aria-describedby="alert-dialog-slide-description"
    PaperProps={{style: {backgroundColor: "lightgray",width: "70%"}}}
  >
    <DialogTitle style={{color: "black",fontSize: "18px",textAlign: 'center'}}>There is odd changes</DialogTitle>
    <DialogTitle style={{color: "black",fontSize: "18px",textAlign: 'center'}}>Are you sure ?</DialogTitle>
    <DialogContent sx={{paddingLeft: 0,paddingRight: 0}}>
      <ListItem button sx={{ color: "black",fontSize: "18px",justifyContent: 'center',padding: '1rem 0' }} onClick={() => handleUserBetting()}>
         Bet
       </ListItem>
       <ListItem button sx={{ color: "black",fontSize: "18px",justifyContent: 'center',padding: '1rem 0' }} onClick={() => handleCheckAgain()}>
         Check Again
       </ListItem>
    </DialogContent>

  </Dialog>
  )
}

