
import { Avatar } from '@mui/material'/** @jsxImportSource @emotion/react */
import { css} from '@emotion/react';
import React, { useState,useRef } from 'react'
import { Form, Input} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import { useEffect } from 'react'
import {Img} from 'react-image'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {ButtonBase} from '@mui/material';
import moment from 'moment';
import io from 'socket.io-client';
import jwtDecode from 'jwt-decode';

import FilterLeague from './filterLeagues'
import Card from './matchCard';
import axios from '../apiClient';
import Alert from '../alert';
import Loader from '../loader';
import NoData from '../noData';
import config from '../../config'

export default function Parlay() {
    
    const navigate = useNavigate();
    const location = useLocation();
    
    const [filterOpen, setFilterOpen] = useState(false)
    const [loading,setLoading] = useState(true)
    const [errorMsg, setErrorMsg] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [bettingMatches,setBettingMatches] = useState([])
    const [favLeagues, setFavLeagues] = useState([])
    const [leagues,setLeagues] = useState([]);
    const [filterLeagues, setFilterLeagues] = useState([])
    const [selectedBetCard,setSelectedBetCard] = useState([])
    const [minBetAmount,setMinBetAmount] = useState();
    const [maxBetAmount,setMaxBetAmount] = useState();
    const socketRef = useRef();

    const [amount, setAmount ] = useState(0);

    async function getAmount() {
      const token = localStorage.getItem("token");
      const {id: userId} = jwtDecode(token);

      const data = await axios.get(`/wallet/getAmount?holderId=${userId}`)

      if (data.code != 200) return;
      setAmount(data.data.amount)
    }

    async function fetchData(leagues,leagueName) {
        setLoading(true)
        const data = await axios.get(`/bet/getBet?betType=mix_parlay&leagues=${leagues}`)

        if (data.code != 200) {
            if(data.code == 404){
                if(leagueName){
                    setErrorMsg({ code: data.code, msg: `${leagueName}"'s matches are not found"` })
                    setOpenAlert(true)
                }
                setLoading(false)
                setFilterLeagues([])
                return
            }
            setErrorMsg({ code: data.code, msg: data.message })
            setOpenAlert(true)
            setLoading(false)
            return
        }
        setBettingMatches(data.data)
        setLoading(false)
    }

    const fetchFavLeagues = async() =>{
        const startOfToday = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss');
        const endOfTomorrow = moment().add(1, 'days').endOf('day').format('YYYY-MM-DD HH:mm:ss');
        const data = await axios.get(`/info/getOddLeagueByDate?startDate=${startOfToday}&endDate=${endOfTomorrow}`)

        if (data.code != 200) {
            setErrorMsg({ code: data.code, msg: data.message })
            setOpenAlert(true)
            setLoading(false)
            return
        }
        setFavLeagues(data.data.favLeauges)
        setLeagues(data.data.leagues)
    }

    const getAmountPerMatch = async () =>{
        const data = await axios.get(`/info/getAmountPerBet`);
        if(data.code == 200){
            setMinBetAmount(Math.round(data?.data?.mix_parlay_min_amount_per_bet))
            setMaxBetAmount(Math.round(data?.data?.mix_parlay_max_amount_per_bet))
        }
    }

    useEffect(()=>{
        getAmount();
        fetchFavLeagues()
        getAmountPerMatch()
        fetchData("")
        socketRef.current = io(config.socketApi,{
            auth: {
                token: localStorage.getItem("token")
            },
  
        });
        socketRef.current.io.on("error", (error) => {
        // console.log("error from socket",error)
        });

        socketRef.current.on('oddUpdated',oddUpdated);

        return () => {
            socketRef.current.off('oddUpdated');
            socketRef.current.disconnect();
            const idsString = localStorage.getItem('timeoutIds')
            if(idsString){
                let idsArrays = JSON.parse(idsString);
                idsArrays.forEach(id => {
                    clearTimeout(id);
                });
                localStorage.setItem('timeoutIds',JSON.stringify([]));
            }
          };
    },[])
    // const oddUpdated = (data) => {
    //     let localData = [];
    //     let normalData = [];
    //     const socketData = data;
    //     setBettingMatches((preState)=>{
    //         localData = [...preState];
    //         return preState;
    //     })
    //     if(localData.length == 0) return;
    //     for (let i = 0; i < socketData.length; i++) {
    //         const socketMatch = socketData[i];
    //         const {matchId: remoteMatchId,hdp: remoteHdp,ou: remoteOu,periods: remotePeriod} = socketMatch;
    //         for (let z = 0; z < localData.length; z++) {
    //             const {match: localMatches} = localData[z];
    //             for (let y = 0; y < localMatches.length; y++) {
    //                 const {matchId :localMatchId,odd: localOdds} = localMatches[y];
    //                 if(localMatchId == remoteMatchId){
    //                     const localOdd = localOdds.find(odd=> odd.periods == remotePeriod);
    //                     const copylocalOdd = JSON.parse(JSON.stringify(localOdd));
    //                     localOdd.hdp = remoteHdp;
    //                     localOdd.ou  = remoteOu;
    //                     normalData = JSON.parse(JSON.stringify(localData));
    //                     if(remoteHdp.converted != copylocalOdd?.hdp?.converted) remoteHdp.change = true;
    //                     if(remoteOu.converted != copylocalOdd?.ou?.converted) remoteOu.change = true;
    //                 }
    //             }
                
    //         }
    //     }

    //     setBettingMatches((preState)=> {
    //         return localData
    //     })
    //     setTimeout(()=>{
    //         setBettingMatches((preState)=> {
    //             return normalData
    //         })
    //     },5000)
       
    // }

    const oddUpdated = (data) => {
        let localData = [];
        let a = []; //changed local data without "change" params
        const socketData = data;

        setBettingMatches((preState)=>{
            localData = [...preState];
            if(localData.length == 0) return localData;
            for (let i = 0; i < socketData.length; i++) {
                const socketMatch = socketData[i];
                const {matchId: remoteMatchId,hdp: remoteHdp,ou: remoteOu,periods: remotePeriod} = socketMatch;
                for (let z = 0; z < localData.length; z++) { // loop the league
                    const {match: localMatches} = localData[z];
                    for (let y = 0; y < localMatches.length; y++) { // loop the matches
                        const {matchId :localMatchId,odd: localOdds} = localMatches[y];
                        if(localMatchId != remoteMatchId) continue;
                        const localOdd = localOdds.find(odd=> odd.periods == remotePeriod);
                        if(!localOdd) continue;
                        const copylocalOdd = JSON.parse(JSON.stringify(localOdd));
                        localOdd.hdp = remoteHdp;
                        localOdd.ou  = remoteOu;
                        a = JSON.parse(JSON.stringify(localData));
                        if(remoteHdp.converted == copylocalOdd?.hdp?.converted && remoteOu.converted == copylocalOdd?.ou?.converted) continue;
                        if(remoteHdp.converted != copylocalOdd?.hdp?.converted) remoteHdp.change = true;
                        if(remoteOu.converted != copylocalOdd?.ou?.converted) remoteOu.change = true;   
                    }  
                }
                const timeoutId = setTimeout(()=>{
                    setBettingMatches((preState)=> {
                        const leagues = [...preState];
                        for (let x = 0; x < leagues.length; x++) { // loop the leagues
                            const league = leagues[x];
                            const matches = league.match;
                            for (let y = 0; y < matches.length; y++) { // loop the matches
                                const match = matches[y];
                                const odds = match.odd;
                                for (let y = 0; y < odds.length; y++) { // loop the odds
                                    const odd = odds[y];
                                    if(odd.hdp.change) odd.hdp.change = false;
                                    if(odd.ou.change) odd.ou.change = false;
                                }  
                            }                           
                        }
                        return leagues
                    })
                },5000)
                let idsString = localStorage.getItem('timeoutIds')
                if(!idsString) idsString = JSON.stringify([]);
                let idsArrays = JSON.parse(idsString);
                idsArrays.push(timeoutId);
                localStorage.setItem('timeoutIds',JSON.stringify(idsArrays));
            }
            return localData
        })

        setSelectedBetCard((preState)=>{
            localData = [...preState];
            if(localData.length == 0) return localData;
            for (let i = 0; i < socketData.length; i++) {
                const socketMatch = socketData[i];
                const {matchId: remoteMatchId,hdp: remoteHdp,ou: remoteOu,periods: remotePeriod} = socketMatch;
      
                const localSelectedMatches = localData;
                for (let y = 0; y < localSelectedMatches.length; y++) {
                    const {matchId :localMatchId,periods: localPeriods,oddType: localOddType} = localSelectedMatches[y];
                    if(localMatchId != remoteMatchId) continue;
                    if(localPeriods != remotePeriod) continue;
                    localSelectedMatches[y].converted = socketMatch[localOddType].converted;
                } 
            }
            return localData
        })
 
    }

    const hanldeFilterLeaguesClick =(e)=>{
       setFilterOpen(true)
       let serverData = [...leagues];
        for (let i = 0; i < filterLeagues.length; i++) {
            const v = filterLeagues[i];
            const index = serverData.findIndex(vv => vv.id == v);
            const copyIndexObj = {...serverData[index]};
            serverData.splice(index, 1); // remove selected element
            serverData.unshift(copyIndexObj); // add selected elemet to the top of the array
        }
        setLeagues(serverData)
    }

    const hanldeFilterLeaguesClose =(e)=>{
        setFilterOpen(false)
    }

    const handleFavLeaguesClick = (league) =>{
        const leagueId = league.id
        // let copyArr = [...filterLeagues];
        let copyArr = [];
        (filterLeagues.includes(leagueId))? copyArr = filterLeagues.filter(v=>v != leagueId) : copyArr.push(leagueId)
        setFilterLeagues(copyArr)
        handleFilterMatchesByLeagues(copyArr,league)
    }

    const handleFilterMatchesByLeagues = (selectedLeagues,selectedLeague) =>{
        let leagueName;
        leagueName = selectedLeague.league_name_en
        let str = "" ;
        for (let i = 0; i < selectedLeagues.length; i++) {
            const v = selectedLeagues[i];
            if(i +1 == selectedLeagues.length){
                str += v 
            }else{
                str += v + ","
            }
        }
        fetchData(str,leagueName)
        setFilterLeagues(selectedLeagues)
    }

    const handleBetMatchClick = (clickedCard) =>{
        const copyArr = selectedBetCard.length > 0 ? [...selectedBetCard] : []
        const sameBoxIndex =copyArr.findIndex(v => v.matchId == clickedCard.matchId && v.periods == clickedCard.periods && v.oddType == clickedCard.oddType && v.selectedTeam == clickedCard.selectedTeam);
        // remove if user clicked same box
        if(sameBoxIndex > -1) return setSelectedBetCard(copyArr.filter((v,index) => index != sameBoxIndex))
        
        const index = copyArr.findIndex(v => v.matchId == clickedCard.matchId && v.periods == clickedCard.periods)
        if(index > -1) copyArr[index] = clickedCard; // update if user clicked same card
        if(index == -1) copyArr.push(clickedCard) // insert if user clicked new card
        setSelectedBetCard(copyArr)
    }


    const onFinish = ({betAmount}) => {
        const selectedMatches = [];
        for (let i = 0; i < selectedBetCard.length; i++) {
            const v = selectedBetCard[i];
            for (let vv = 0; vv < bettingMatches.length; vv++) {
                const {match: matches} = bettingMatches[vv];
                for (let vvv = 0; vvv < matches.length; vvv++) {
                    const match = {...matches[vvv]};
                    if(match.matchId == v.matchId) {
                        match.selectedPeriods = v.periods;
                        selectedMatches.push(match);
                    }
                }  
            }
        }
        if(selectedBetCard.length == 1 ){
            setErrorMsg({ code: 404, msg: `Bet Match must be at least two`,info: true })
            setOpenAlert(true)
            return
        }
        if(betAmount > amount ){
            setErrorMsg({ code: 404, msg: `You don't have sufficient balance to make this bet`,info: true })
            setOpenAlert(true)
            return
        }
        const obj = {selectedMatches,selectedCard: selectedBetCard,betAmount} 
        navigate('/bet/summary',{state: obj});
    };

    const onFinishFailed = (errorInfo) => {
    //   console.log("Failed:", errorInfo);
    };

    const handleCloseAlert =()=>{
        setOpenAlert(false)
    }
  
  return (
    <div >
        {loading && <Loader bet/>}
        {!loading &&
        <div>
            <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
            <div style={{display: "flex",height: `calc(100vh - 190px)`}}>
                <div style={{flexBasis: "65%"}}>
                    <div style={{display: "flex",padding: '1rem'}}>
                        <div style={{display: "flex",flexDirection: "row",justifyContent: "space-evenly",flexBasis: "90%"}}>
                          {favLeagues?.map((v) => {
                            return (
                                <ButtonBase style={{borderRadius: "50px"}} key={v.id}>
                                    <Avatar alt="Logo"   sx={{borderColor: "red",borderWidth: `${filterLeagues.includes(v.id)? '2px': "0px"}`,borderStyle: 'solid',cursor: "pointer"}} onClick={()=>handleFavLeaguesClick(v)}>
                                    <Img src={v.logo} style={{objectFit: "cover",borderRadius: "50px"}} width='100%' height='100%'  loader={<Skeleton width={40} height={40} circle/>}/>
                                    {/* <img src={v.logo} style={{objectFit: "cover",borderRadius: "50px"}} width='25px' height='25px' />   */}
                                    </Avatar>
                                </ButtonBase>
                            )
                          })}
                        </div>
                        <div style={{flexBasis: "10%"}}>
                            <ButtonBase style={{padding: "0.2rem 0 0.2rem 2rem",display: 'flex',alignItems: "center",backgroundColor: "lightgray",borderRadius: "0.9rem",cursor: "pointer",fontFamily: "inherit"}} onClick={hanldeFilterLeaguesClick}>
                                <h3 style={{margin: 0}}>Leagues</h3>
                                {filterOpen ? <ExpandLess style={{width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0}}/> : <ExpandMore style={{width: "3.6rem",height: "2rem",borderRadius: "3rem",marginTop: 0}}/>}
                            </ButtonBase>
                        </div>
                        {filterOpen && <FilterLeague leaguesArr={leagues} filterLeagues={filterLeagues} handleFilterMatchesByLeagues={handleFilterMatchesByLeagues} hanldeFilterLeaguesClose={hanldeFilterLeaguesClose} open ={filterOpen} />}
                    </div>
                    <div style={{padding: "1rem 7rem"}}
                        css={css`
                        overflow-y: auto;
                        height: calc(100vh - 240px);
                        -ms-overflow-style: none;  /* Internet Explorer 10+ */
                        scrollbar-width: none;  /* Firefox */
                        ::-webkit-scrollbar {
                           display: none;  /* Safari and Chrome */
                       }
                      `}
                    >
                        {  bettingMatches.length == 0 ?
                            <NoData msg={'There is no bet match'} height="60vh"/> :
                            bettingMatches.map(({league,match: matches})=>{
                                return (
                                    <div key={league.id} style={{marginBottom: "3rem"}}>
                                        <div style={{display: "flex",alignItems: 'center'}}>
                                           <Avatar style={{objectFit: "cover",width: "3rem",height: "3rem",borderRadius: "3rem"}}>
                                            <Img src={league.logo} style={{objectFit: "cover",width: "3rem",height: "3rem",borderRadius: "3rem"}} loader={<Skeleton width={30} height={30} circle/>}/>
                                           </Avatar>
                                           {/* <img src={league.logo} style={{}}/> */}
                                           <h3 style={{marginLeft: "0.8rem"}}>{league.name_en}</h3>
                                        </div>
                                        {
                                            matches.map((matchData)=>{
                                                let copyArr =selectedBetCard.length > 0 ? [...selectedBetCard] : []
                                                let arr = copyArr.filter(v=>v.matchId == matchData.matchId );
                                                if(!arr) arr=[];
                
                                                return(
                                                    <Card key={matchData.matchId} matchData={matchData} handleBetMatchClick={handleBetMatchClick} selectedBetCard={arr} inBet/>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
                <div style={{flexBasis: "35%",alignItems: "end",display: 'flex',borderColor: "gray",borderStyle: "solid",borderWidth: "0 0 0 1px"}}>
                    <div style={{padding: "2rem 2rem"}}>
                            <Form
                                name="basic"
                                style={{
                                    fontFamily: "inherit",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between"
                                }}
                                initialValues={{
                                    remember: true,
                                }}
                                className="bet-amount"
                                onFinish={onFinish}
                                onFinishFailed={onFinishFailed}
                                autoComplete="off"
                            >
                                <Form.Item
            
                                    name="betAmount"
                                    // className="custom-input"
                                    hasFeedback
                                    rules={[
                                        ({ getFieldValue }) => ({
            
                                        validator(_, value) {
                                            if ( minBetAmount <= value && value <= maxBetAmount) {
                                            return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(`Bet Amount must be between ${minBetAmount} And ${maxBetAmount} !`));
                                        },
                                        }),
                                    ]}
                                    style={{ padding: "0", color: "white" , flexBasis: "80%"}}
                                >
                                    <Input
                                    onKeyDown={(e)=> ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    size='large'
                                    type='number' placeholder='Enter the amount' 
                                    />
                                </Form.Item>
                                <ButtonBase disabled={selectedBetCard.length == 0  && true} type='submit' component="button" style={{marginBottom: "24px",marginLeft: "1rem" ,color: "black", backgroundColor: "lightslategray", padding: "0.8rem 2rem", borderRadius: "1rem", fontFamily: "inherit"}} >
                                    <h4 style={{margin: 0 }}>Bet</h4>
                                </ButtonBase>
            
                            </Form>
                        <div >
                            <div style={{display: "flex",justifyContent: "space-between"}}>
                                <h3 style={{margin: 0,padding: "2rem 0 0 0", fontWeight: "normal"}}>
                                    Parlay
                                </h3>
                                <h3 style={{margin: 0,padding: "2rem 0 0 0",fontWeight: "normal"}}>
                                    {selectedBetCard.length > 0 ? selectedBetCard.length : "-"}
                                </h3>
                            </div>
                            <div style={{display: "flex",justifyContent: "space-between"}}>
                                <h3 style={{margin: 0,padding: "2rem 0 0 0", fontWeight: "normal"}}>
                                    Balance
                                </h3>
                                <h3 style={{margin: 0,padding: "2rem 0 0 0",fontWeight: "normal"}}>
                                    {Number(amount).toFixed(2)}
                                </h3>
                            </div>
            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
    </div>
  )
}




