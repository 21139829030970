import { DatePicker, Space, Switch, Table } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

import axios from '../apiClient';
import Alert from '../alert';

const columns = [
  {
    title: 'Kickoff Time',
    dataIndex: 'matchDate',
    key: 'key',
  },
  {
    title: 'Match',
    dataIndex: 'name',
    key: 'key',
  },
  {
    title: 'Match Status',
    dataIndex: 'matchStatus',
    key: 'key',
  },
  {
    title: '1st Half Score',
    dataIndex: 'firstHalfScore',
    key: 'key',
  },
  {
    title: '2nd Half Score',
    dataIndex: 'secondHalfScore',
    key: 'key',
  },
  {
    title: 'Full Time Score',
    dataIndex: 'fullTimeScore',
    key: 'key',
  },
];
// const data = [
//   {
//     id: 0,
//     name: 'Premier League',
//     children: [
//       {
//         id: 1,
//         name: 'John',
//       },
//       {
//         id: 1,
//         name: 'hello',
//       },
//     ],
//   },
//   {
//     id: 2,
//     name: 'jager League',
//     children: [
//       {
//         id: 100,
//         name: 'John Brown',
//         age: 42,
//         address: 'New York No. 2 Lake Park',
//       },
//       {
//         id: 3,
//         name: 'hello',
//         age: 42,
//         address: 'New York No. 2 Lake Park',
//       },
//     ],
//   },

// ];


const MatchResult = () => {
  const [date,setDate] = useState('')
  const [matchResults,setMatchResults] = useState([])
  const [loading,setLoading] = useState(true)
  const [errorMsg, setErrorMsg] = useState({});
  const [openAlert, setOpenAlert] = useState(false);
  const [expandRowKeys, setExpandRowKeys] = useState([]);

  const didMount = useRef(false)

  useEffect(()=>{
    const today = moment().format('DD-MM-YYYY');
    setDate(today)
  },[])

  useEffect(()=>{
    if(didMount.current == true){
      const arr = matchResults.map(v=>v.key)
      setExpandRowKeys(arr)
    }
  },[matchResults])

  async function fetchData() {
    setLoading(true)
    let data = await axios.get(`/report/matchResult?matchDate=${moment(date, 'DD-MM-YYYY').format('YYYY-MM-DD')}&leagues=`)

    if (data.code != 200) {
        if(data.code == 404){
            setLoading(false)
            setMatchResults([])
            return
        }
        setErrorMsg({ code: data.code, msg: data.message })
        setOpenAlert(true)
        setLoading(false)
        return
      }
      
    const formattedData = data.data.map((v,index) => {
        expandRowKeys.push(index.toString())
        let obj = {};
        obj.key = index;
        obj.matchDate = v.league.name_en;
        obj.children = [];
        v.match.forEach((vv,index)=>{
          obj.children.push({
            name : vv.homeTeam.name_en + "-" + vv.awayTeam.name_en,
            id: index,
            matchDate : moment(vv.matchDate).format("DD-MM-YYYY h:mm A"),
            matchStatus : vv.status,
            firstHalfScore: vv.scores.halftime.home != null ? vv.scores.halftime.home + "-" + vv.scores.halftime.away : "-",
            secondHalfScore: vv.scores.fulltime.home != null ? (vv.scores.fulltime.home - vv.scores.halftime.home) + "-" + (vv.scores.fulltime.away -vv.scores.halftime.away) : "-",
            fullTimeScore : vv.scores.fulltime.home != null ? vv.scores.fulltime.home + "-" + vv.scores.fulltime.away : "-",
          })
        })
       return obj;
    })
    setMatchResults(formattedData)
    setLoading(false)
  }
  
  useEffect(()=>{
    if(didMount.current){
      fetchData()
    }else{
      didMount.current = true
    }
  },[date])

  const handleDateChange = (value,dateString) => setDate(dateString)

  const handleCloseAlert = () => setOpenAlert(false)
  // console.log("expandrowkeys",expandRowKeys)
  // console.log("matchResult",matchResults)

  return (
    <>
     <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
      <Space
        align="center"
        style={{
          marginBottom: 16,
        }}
      >
         <DatePicker onChange={handleDateChange} format={'DD-MM-YYYY'} value={date ? dayjs(date,"DD-MM-YYYY") : ''}/>
      </Space>
      <Table
        columns={columns}
        loading={loading}
        rowClassName = {(record,index)=> record.children ? 'table-row-light-violet' :  'table-row-white'}
        dataSource={matchResults}
        pagination={false}
        expandable={{expandRowByClick: false,expandIcon: ()=> null,expandedRowKeys: expandRowKeys}}
      />
    </>
  );
};
export default MatchResult;