 /** @jsxImportSource @emotion/react */
 import { css} from '@emotion/react';
import React, { useEffect,useState } from 'react'
import { languageContext } from '../../context';
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import Card from './matchCard'
import { Button, ButtonBase, Dialog, DialogActions, DialogTitle } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import ReactToPrint from 'react-to-print';
import PrintCard from './printMatchCard';

export default function BetDetail() {
    const location = useLocation();
    const [selectedCard,setSelectedCard] = useState([]);
    const [selectedMatches,setSelectedMatches] = useState([]);
    const [printerDialogOpen,setPrinterDialogOpen] = useState(false);
    // console.log('location.state',location.state)
    const {shortId,betType,betStatus,bet,betAmount,winnedAmount,betDate} = location.state.rowData;
    const data = location.state.rowData;
    let printerRef = null;

    const parlay = selectedCard?.length > 1 ;
    const single = selectedCard?.length == 1 ;
    
    useEffect(()=>{
      const selectedCardArr = [];
      const selectedMatchesArr = [];
      for (let i = 0; i < bet.length; i++) {
        const v = bet[i];
        const {converted,match_id : matchId , odd_type : oddType,periods, selected_team: selectedTeam} = v.betData
        selectedCardArr.push({converted, matchId,oddType,periods,selectedTeam})
        const {awayTeam,homeTeam,matchDate,scores} = bet[i].match;
        let goals;
        if(periods == 'full_time') goals = scores.fulltime
        if(periods == 'first_time') goals = scores.halftime
        if(periods == 'second_time') goals = {away: scores.fulltime.away - scores.halftime.away,home: scores.fulltime.home - scores.halftime.home}
        const odd = [bet[i].odd];
        selectedMatchesArr.push({awayTeam,homeTeam,matchDate,matchId,odd,goals,status: bet[i].status,selectedPeriods: periods})
        setSelectedCard(selectedCardArr)
        setSelectedMatches(selectedMatchesArr)
      }
    },[])

    const getSlipStatus = (data) =>{
      let status ;
      for (let i = 0; i < data.bet.length; i++) {
        const v = data.bet[i];
        if(v.status == 'pending'){
            status = "pending"
            return status
        }
        if(v.status == 'loss'){
            status = "loss"
            return status
        }
        if(betType == 'single' && v.status == 'refund'){
            status = "refund"
            return status
        }
        // if(betType == 'single' && v.status == 'draw'){
        //     status = "draw"
        //     return status
        // }
      }
      if(betType == "mix_parlay"){
        if(data.bet.every(v => v.status == 'refund')){ // if all matches are refunded in parlay, status must be refund
          status = 'refund';
          return status;
        }
      }
      status = "win";
      return status
    }
    const slipStatus = getSlipStatus(data);
    
    function getSlipStatusBg(){
      let backgroundColor;
      if(slipStatus == 'pending') backgroundColor= "#A47E3B"
      if(slipStatus == 'win') backgroundColor= "#408E91"
      if(slipStatus == 'loss') backgroundColor= "#9C254D"
      if(slipStatus == 'refund') backgroundColor= "rgb(100 39 118)"
      if(slipStatus == 'draw') backgroundColor= "rgb(47 156 37)"
      return backgroundColor;
    }

    let backgroundColor = getSlipStatusBg();

    function getSlipStatusText(){
      let word;
      if(slipStatus == "pending") word = 'playing' ;
      if(slipStatus == "win") word = 'win' ;
      if(slipStatus == "loss") word = 'lose' ;
      if(slipStatus == "refund") word = 'refund' ;
      if(slipStatus == "draw") word = 'draw' ;
      return word;
   }

   const resetPrinter = () => {
    setPrinterDialogOpen(false);
  }

   const renderPrintDialog         =       () => {
    return (
      <Dialog
      open={printerDialogOpen}
      onClose={()=>resetPrinter()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
     <DialogActions>
        {renderReceiptPrinterView()}
     </DialogActions>
      <DialogActions>
        <Button onClick={()=>resetPrinter()} color="primary">
          Cancel
        </Button>
        <Button onClick={()=>resetPrinter()}>
          <ReactToPrint
          trigger={() => <Button>Print</Button>}
          content={() => printerRef}
          />
        </Button>
        
      </DialogActions>
    </Dialog>
    )
  }

   const renderReceiptPrinterView  =       () => {
    
    return (
    <div ref={el => (printerRef = el)} style={{padding: "0 1rem",width: '300px'}}>
      <div style={{padding: "0"}} className="white">
         <div style={{display: "flex",justifyContent: "center",marginBottom: "1rem",marginTop: '1rem'}}>
           <h3 style={{margin: 0}}>Bet Slip</h3>
         </div>
         <div style={{padding: "0 1rem 1rem 1rem",border: "1px solid grey",borderRadius: "1.3rem"}}>
          <div style={{display: "flex",justifyContent: 'space-between',margin: "1rem 0"}}>
             <h3 style={{margin: 0}}>ID</h3>
             <h3 style={{margin: 0}}>{shortId}</h3>
           </div>
           <div style={{display: "flex",justifyContent: 'space-between',margin: "1rem 0"}}>
             {single ? <h3 style={{margin: 0}}>Type</h3> :<h3 style={{margin: 0}}>Parlay</h3>}
             {single ? <h3 style={{margin: 0}}>HDP</h3>  : <h3 style={{margin: 0}}>{selectedCard.length}</h3>}
           </div>
           <div  style={{display: "flex",justifyContent: 'space-between',margin: "0",paddingBottom: "1rem"}}>
             <h3 style={{margin: 0}}>Betted money</h3>
             <h3 style={{margin: 0}}>{Number(betAmount).toFixed(2)}</h3>
           </div>
           <div style={{display: "flex",justifyContent: 'space-between',margin: "0.3rem 0"}}>
             <h4 style={{margin: 0}}>{moment(betDate).format('DD-MM-YYYY h:mmA')}</h4>
           </div>
         </div>
      </div>
      <div className='white' >
         {
            selectedMatches.map((v)=>{
                let copyArr =selectedCard.length > 0 ? [...selectedCard] : []
                let obj = copyArr.filter(vv=>vv.matchId == v.matchId && vv.periods == v.selectedPeriods)[0]
                if(!obj) obj={};
                
                return(
                    <PrintCard summary betTime={obj.periods} key={`${v.matchId}${v.selectedPeriods}`} matchData={v} handleBetMatchClick={()=>{}} selectedBetCard={[obj]} />
                )
            })
        }
      </div>
      
      </div>
    )
  }
    
  return (
    <div>

      <div style={{display:'flex',justifyContent: 'end',marginRight: '20%'}}>
        <ButtonBase onClick={()=> setPrinterDialogOpen(true)} >
            <PrintIcon style={{fontSize: "2rem"}}/>
        </ButtonBase>
      </div>
      <div style={{padding: "0 20rem"}} className="white">
        <div 
        css={css
          `
          margin: 1rem 0;
          border-radius: 1rem;
          box-shadow: -2px 2px 12px -3px rgba(0, 0, 0, 0.3);
          -webkit-box-shadow: -2px 2px 12px -3px rgba(0, 0, 0, 0.3);
          -moz-box-shadow: -2px 2px 12px -3px rgba(0, 0, 0, 0.3);
          `}
        style={{padding: "1rem",margin: "1rem 0 3rem 0",display: "flex",justifyContent: "space-between",backgroundColor: "white",borderRadius: "1rem"}}>
            <div style={{display: "flex",flexDirection: "column"}}>
                <div style={{display: "flex"}}>
                    <h5 style={{color: "black",fontWeight: "normal",margin: "0 0.4rem 1rem 0"}}>No</h5>
                    <h5 style={{color: "black",fontWeight: "normal",margin: "0 0 1rem 0"}}>{shortId}</h5>
                </div>
                {betType == "mix_parlay" && <h5 style={{color: "black",fontWeight: "normal",margin: "0 0 0.8rem 0"}}>Parlay</h5>}
                {betType == "mix_parlay" && <h4 style={{color: "black",margin: "0 0 1rem 0"}}>{bet.length}</h4>}
                {betType == "single" && <h5 style={{color: "black",fontWeight: "normal",margin: "0.8rem 0 0.8rem 0"}}>Betted date</h5>}
                <h5 style={{fontWeight: "normal",margin: 0,color: "black"}}>{moment(betDate).format('DD-MM-YYYY h:mmA')}</h5>
            </div>
            <div style={{display: "flex",flexDirection: "column"}}>
                <h5 style={{color: "black",fontWeight: "normal",margin: "0 0 0.8rem 0"}}>Betted money</h5>
                <h4 style={{color: "black",margin: "0 0 1rem 0"}}>{betAmount }</h4>
                <h5 style={{color: "black",fontWeight: "normal",margin: "0 0 0.8rem 0"}}>Won money</h5>
                <h4 style={{margin: 0,color: "rgb(28 191 162)"}}>{winnedAmount > 0 ? winnedAmount : "-"}</h4>
            </div>
            <div style={{display: "flex",flexDirection: "column"}}>
                <div style={{padding: "0.6rem 0.8rem",backgroundColor: backgroundColor,borderRadius: "1rem",fontWeight: "bold",color: "white"}}>
                  {getSlipStatusText()}
                </div>
            </div>
        </div>
        {
            selectedMatches.map((v)=>{
                let copyArr =selectedCard.length > 0 ? [...selectedCard] : []
                let arr =[];
                arr = copyArr.filter(vv=>vv.matchId == v.matchId  && vv.periods == v.selectedPeriods)

                return(
                    <Card goals={v.goals} key={v.matchId} summary betTime={arr[0].periods}  matchData={v} handleBetMatchClick={()=>{}} selectedBetCard={arr} />
                )
            })
        }
      </div>
      {renderPrintDialog()}
    </div>
 
  )
}
