// assets
import { IconHome  } from '@tabler/icons';

// constant
const icons = { IconHome  };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const bet = {
    id: 'bet',

    type: 'group',
    children: [
        {
            id: 'bet',
            title: 'Bet',
            type: 'item',
            url: '/bet',
            icon: icons.IconHome ,
            breadcrumbs: true
        }
    ]
};

export default bet;
