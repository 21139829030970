// assets
import { IconHome  } from '@tabler/icons';

// constant
const icons = { IconHome  };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const matchResult = {
    id: 'matchResult',

    type: 'group',
    children: [
        {
            id: 'matchResult',
            title: 'Match Results',
            type: 'item',
            url: '/matchResult',
            icon: icons.IconHome ,
            breadcrumbs: true
        }
    ]
};

export default matchResult;
