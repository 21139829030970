import React,{useEffect, useRef, useState} from 'react'
import AuthWrapper1 from './AuthWrapper1';
import AuthCardWrapper from './AuthCardWrapper';
import AuthLogin from './AuthLogin';
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Navigate } from 'react-router-dom';

import axios from '../apiClient';
import Alert from "../alert"


export default function Login() {
    const [errorMsg, setErrorMsg] = useState({});
    const [openAlert, setOpenAlert] = useState(false);
    const [loading,setLoading] = useState(false)
    const count = useRef(0);

    const theme = useTheme();
    useEffect(()=>{
        count.current = count.current + 1;
    })
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    // get data from backend when componenet is first mounted
    async function fetchData(username,password) {
    setLoading(true)
    const params ={
        "uname": username,
        "psw": password
    }
    const data = await axios.post(`/member/loginSeller`,params)
    setErrorMsg({code: data.code,msg: data.message})
    setOpenAlert(true)
    setLoading(false)
    if(data.code != 200) return
    const token = data.data.token; 
    const shareName = data.data.shareName;
    localStorage.setItem("token", token);
    localStorage.setItem("shareName", shareName);
    window.location.reload()
}
  //--------------------------------------------------
  const handleCloseAlert =()=>{
    setOpenAlert(false)
  }
  
  return (
     <AuthWrapper1>
         <Alert openAlert={openAlert} errorMsg={errorMsg} handleCloseAlert={handleCloseAlert}/>
            {(count.current == 0 && localStorage.getItem("token")) ? <Navigate to="/bet"/> :
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <AuthCardWrapper>
                                <Grid container spacing={2} alignItems="center" justifyContent="center">
                                    <Grid item sx={{ mb: 3 }}>
                                        <h2 style={{margin: "0"}} className='logo-style'>KL 36</h2>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid
                                            container
                                            direction={matchDownSM ? 'column-reverse' : 'row'}
                                            alignItems="center"
                                            justifyContent="center"
                                        >
                                            <Grid item>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography
                                                        color={theme.palette.secondary.main}
                                                        gutterBottom
                                                        variant={matchDownSM ? 'h3' : 'h2'}
                                                    >
                                                        Home Site
                                                    </Typography>
                                                  
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AuthLogin loading={loading} fetchData={fetchData} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                    <Grid item xs={12}>
                                       
                                    </Grid>
                                </Grid>
                            </AuthCardWrapper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    {/* <AuthFooter /> */}
                </Grid>
            </Grid>
            }
    </AuthWrapper1>
  )
}
