// assets
import { IconHome  } from '@tabler/icons';

// constant
const icons = { IconHome  };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const history = {
    id: 'history',

    type: 'group',
    children: [
        {
            id: 'history',
            title: 'History',
            type: 'item',
            url: '/history',
            icon: icons.IconHome ,
            breadcrumbs: true
        }
    ]
};

export default history;
